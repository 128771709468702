  .message-editor {
    display: flex;
    flex-direction: column;
    background: transparent;
    padding: 12px 15px;
    height: 100%;
  }

  .message-view {
    /* pick relevant styles from .ql-container to enable wysiwyg */
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    box-sizing: border-box;
    margin: -12px -15px;
    /* inherited: */
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    letter-spacing: 0.15008px; /* 0.00938em - computes to different px value*/
  }

  .ql-container {    
    overflow: auto;
  }

  .ql-tooltip {   
    left:unset !important;
    margin-left: 15px;
  }

  /*text style*/
  .ql-editor p {    
    padding-top: 12px;
  }

  .ql-size-small {
    font-size: 0.75em;
  }
  .ql-size-large {
    font-size: 1.5em;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }  

  .ql-indent-1 {
    text-indent : 2em;
  }
  .ql-indent-2 {
    text-indent : 4em;
  } 
  .ql-indent-3 {
    text-indent : 6em;
  }
  .ql-indent-4 {
    text-indent : 8em;
  }   
  .ql-indent-5 {
    text-indent : 10em;
  }  
  
